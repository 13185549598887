import React, { useState } from "react";
import { motion } from "framer-motion";

const successProjects = [
  {
    id: 1,
    title: "Restauración del Teatro Clásico",
    description:
      "Un proyecto de renovación que devolvió el esplendor a un histórico teatro.",
    before: "/images/theater-restoration-before.jpg",
    after: "/images/theater-restoration.jpg",
    funding: "€500,000",
    duration: "18 meses",
  },
  {
    id: 2,
    title: "Festival de Cine Independiente",
    description:
      "De un pequeño evento local a un festival internacional de renombre.",
    before: "/images/indie-film-festival-before.jpg",
    after: "/images/indie-film-festival.jpg",
    funding: "€250,000",
    duration: "12 meses",
  },
  // Puedes añadir más proyectos aquí si lo deseas
];

const ProjectCard = ({ project }) => {
  const [isFlipped, setIsFlipped] = useState(false);

  return (
    <motion.div
      className="bg-white rounded-lg shadow-xl overflow-hidden cursor-pointer"
      whileHover={{ scale: 1.05 }}
      onClick={() => setIsFlipped(!isFlipped)}
    >
      <div className="relative h-48 md:h-64">
        <motion.img
          src={isFlipped ? project.after : project.before}
          alt={project.title}
          className="w-full h-full object-cover"
          initial={false}
          animate={{ opacity: isFlipped ? 1 : 0 }}
          transition={{ duration: 0.5 }}
        />
        <motion.img
          src={isFlipped ? project.before : project.after}
          alt={project.title}
          className="w-full h-full object-cover absolute top-0 left-0"
          initial={false}
          animate={{ opacity: isFlipped ? 0 : 1 }}
          transition={{ duration: 0.5 }}
        />
        <div className="absolute bottom-0 left-0 right-0 bg-gradient-to-t from-black to-transparent p-4">
          <h3 className="text-white text-xl font-bold">{project.title}</h3>
        </div>
      </div>
      <div className="p-4">
        <p className="text-gray-600 mb-2">{project.description}</p>
        <div className="flex justify-between text-sm">
          <span className="font-bold">Financiación: {project.funding}</span>
          <span>Duración: {project.duration}</span>
        </div>
      </div>
    </motion.div>
  );
};

export const SuccessGallery = () => {
  return (
    <section className="bg-gray-100 py-16">
      <div className="container mx-auto px-4">
        <h2 className="text-3xl font-bold text-center mb-12">
          Proyectos Exitosos
        </h2>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {successProjects.map((project) => (
            <ProjectCard key={project.id} project={project} />
          ))}
        </div>
      </div>
    </section>
  );
};
