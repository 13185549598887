import React, { useState } from "react";
import { motion } from "framer-motion";
import { TrendingUp, Users, Calendar, MapPin, ArrowRight } from "lucide-react";
import { useNavigate } from "react-router-dom";

const ProjectCard = ({ project, onViewDetails }) => {
  return (
    <motion.div
      className="bg-white rounded-lg overflow-hidden shadow-md hover:shadow-xl transition-all duration-300"
      whileHover={{ y: -5, scale: 1.02 }}
    >
      <div className="relative">
        <img
          src={project.image}
          alt={project.title}
          className="w-full h-48 object-cover"
        />
        <div className="absolute top-0 right-0 bg-white bg-opacity-90 m-2 px-2 py-1 rounded text-sm font-semibold">
          {project.category}
        </div>
      </div>
      <div className="p-4">
        <h3 className="text-xl font-serif font-bold mb-2 text-gray-800">
          {project.title}
        </h3>
        <p className="text-gray-600 text-sm mb-4 line-clamp-2">
          {project.description}
        </p>
        <div className="mb-4">
          <div className="relative pt-1">
            <div className="overflow-hidden h-2 mb-4 text-xs flex rounded bg-gray-200">
              <motion.div
                style={{ width: `${project.fundingProgress}%` }}
                className="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-indigo-500"
                initial={{ width: 0 }}
                animate={{ width: `${project.fundingProgress}%` }}
                transition={{ duration: 1, ease: "easeOut" }}
              />
            </div>
          </div>
          <div className="flex justify-between text-sm text-gray-600">
            <span className="font-semibold">
              {project.fundingProgress}% financiado
            </span>
            <span className="flex items-center">
              <Users size={16} className="mr-1" />
              {project.backers} inversores
            </span>
          </div>
        </div>
        <div className="text-sm text-gray-600 space-y-1">
          <p className="flex items-center">
            <MapPin size={16} className="mr-2 text-indigo-500" />
            {project.location}
          </p>
          <p className="flex items-center">
            <Calendar size={16} className="mr-2 text-indigo-500" />
            {project.date}
          </p>
        </div>
        <button
          onClick={() => onViewDetails(project.id)}
          className="mt-4 inline-flex items-center justify-center w-full bg-indigo-600 hover:bg-indigo-700 text-white font-bold py-2 px-4 rounded transition duration-300"
        >
          Ver detalles
          <ArrowRight size={16} className="ml-2" />
        </button>
      </div>
    </motion.div>
  );
};

export const TrendingProjects = () => {
  const [currentCategory, setCurrentCategory] = useState("TODOS");
  const navigate = useNavigate();

  const projects = [
    {
      id: 1,
      title: "Festival de Cine Indie",
      category: "CINE",
      image: "/images/indie-film-festival.jpg",
      description:
        "Un festival que celebra el cine independiente y experimental, presentando obras innovadoras de cineastas emergentes.",
      fundingProgress: 75,
      backers: 230,
      location: "Madrid",
      date: "15-17 Septiembre, 2024",
    },
    {
      id: 2,
      title: "Gira Nacional de Jazz",
      category: "MÚSICA",
      image: "/images/jazz-tour.jpg",
      description:
        "Una gira que lleva el mejor jazz a todas las esquinas del país, conectando artistas locales e internacionales.",
      fundingProgress: 60,
      backers: 180,
      location: "Varias ciudades",
      date: "Octubre - Noviembre, 2024",
    },
    {
      id: 3,
      title: "Restauración de Teatro",
      category: "TEATRO",
      image: "/images/theater-restoration.jpg",
      description:
        "Proyecto para devolver el esplendor a un histórico teatro, preservando su arquitectura y mejorando sus instalaciones.",
      fundingProgress: 90,
      backers: 315,
      location: "Barcelona",
      date: "Enero - Junio, 2025",
    },
  ];

  const categories = ["TODOS", "CINE", "MÚSICA", "TEATRO"];

  const filteredProjects =
    currentCategory === "TODOS"
      ? projects
      : projects.filter((project) => project.category === currentCategory);

  const handleViewDetails = (projectId) => {
    navigate(`/project/${projectId}`);
    window.scrollTo(0, 0);
  };

  return (
    <section className="py-16 bg-gray-50">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex justify-between items-center mb-8">
          <h2 className="text-3xl font-serif font-bold text-gray-800 flex items-center">
            <TrendingUp className="mr-2 text-indigo-600" /> Proyectos Trending
          </h2>
          <button
            onClick={() => {
              navigate("/projects");
              window.scrollTo(0, 0);
            }}
            className="text-indigo-600 hover:text-indigo-800 transition-colors font-semibold flex items-center"
          >
            Ver todos los proyectos
            <ArrowRight size={16} className="ml-1" />
          </button>
        </div>
        <div className="mb-8 flex justify-center space-x-4">
          {categories.map((category) => (
            <motion.button
              key={category}
              onClick={() => setCurrentCategory(category)}
              className={`px-4 py-2 rounded-full text-sm font-semibold transition-colors ${
                currentCategory === category
                  ? "bg-indigo-600 text-white"
                  : "bg-white text-gray-700 hover:bg-gray-100"
              }`}
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
            >
              {category}
            </motion.button>
          ))}
        </div>
        <motion.div
          className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
        >
          {filteredProjects.map((project) => (
            <ProjectCard
              key={project.id}
              project={project}
              onViewDetails={handleViewDetails}
            />
          ))}
        </motion.div>
      </div>
    </section>
  );
};
