import React, { useState } from "react";
import { PieChart, Pie, Cell, ResponsiveContainer, Tooltip } from "recharts";
import { motion } from "framer-motion";

const impactData = [
  { name: "Cine", value: 4000, color: "#FF6384" },
  { name: "Música", value: 3000, color: "#36A2EB" },
  { name: "Teatro", value: 2000, color: "#FFCE56" },
  { name: "Artes Visuales", value: 2500, color: "#4BC0C0" },
  { name: "Literatura", value: 1500, color: "#9966FF" },
];

const ImpactMetric = ({ label, value, color }) => (
  <motion.div
    className="text-center"
    initial={{ opacity: 0, y: 20 }}
    animate={{ opacity: 1, y: 0 }}
    transition={{ duration: 0.5 }}
  >
    <div className="text-3xl font-bold" style={{ color }}>
      {value}
    </div>
    <div className="text-gray-600">{label}</div>
  </motion.div>
);

export const CulturalImpact = () => {
  const [activeIndex, setActiveIndex] = useState(null);

  const onPieEnter = (_, index) => {
    setActiveIndex(index);
  };

  return (
    <section className="bg-white py-16">
      <div className="container mx-auto px-4">
        <h2 className="text-3xl font-bold text-center mb-12">
          Nuestro Impacto Cultural
        </h2>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8 items-center">
          <div className="h-80">
            <ResponsiveContainer width="100%" height="100%">
              <PieChart>
                <Pie
                  data={impactData}
                  cx="50%"
                  cy="50%"
                  innerRadius={60}
                  outerRadius={80}
                  fill="#8884d8"
                  paddingAngle={5}
                  dataKey="value"
                  onMouseEnter={onPieEnter}
                >
                  {impactData.map((entry, index) => (
                    <Cell
                      key={`cell-${index}`}
                      fill={entry.color}
                      stroke={activeIndex === index ? "#fff" : "none"}
                      strokeWidth={activeIndex === index ? 2 : 0}
                    />
                  ))}
                </Pie>
                <Tooltip />
              </PieChart>
            </ResponsiveContainer>
          </div>
          <div className="grid grid-cols-2 gap-6">
            <ImpactMetric
              label="Proyectos Financiados"
              value="500+"
              color="#FF6384"
            />
            <ImpactMetric
              label="Inversores Activos"
              value="1000+"
              color="#36A2EB"
            />
            <ImpactMetric
              label="Fondos Recaudados"
              value="€10M+"
              color="#FFCE56"
            />
            <ImpactMetric
              label="Empleos Generados"
              value="2000+"
              color="#4BC0C0"
            />
          </div>
        </div>
      </div>
    </section>
  );
};
