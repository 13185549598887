import React, { useState, useEffect } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { ChevronLeft, ChevronRight } from "lucide-react";

const testimonials = [
  {
    id: 1,
    name: "María González",
    role: "Directora de Cine",
    image: "/placeholder.svg?height=100&width=100",
    quote:
      "PRO_MECENAS ha transformado la forma en que financiamos nuestros proyectos culturales. La plataforma es intuitiva y los incentivos fiscales son un gran atractivo para los inversores.",
  },
  {
    id: 2,
    name: "Carlos Rodríguez",
    role: "Inversor",
    image: "/placeholder.svg?height=100&width=100",
    quote:
      "Como inversor, PRO_MECENAS me ha permitido diversificar mi cartera mientras apoyo proyectos culturales emocionantes. El proceso es transparente y los beneficios fiscales son excelentes.",
  },
  {
    id: 3,
    name: "Laura Martínez",
    role: "Artista Visual",
    image: "/placeholder.svg?height=100&width=100",
    quote:
      "Gracias a PRO_MECENAS, pude financiar mi exposición de arte contemporáneo. La plataforma me conectó con inversores que realmente entendieron y apoyaron mi visión artística.",
  },
];

const Testimonial = ({ testimonial }) => (
  <motion.div
    key={testimonial.id}
    initial={{ opacity: 0, y: 50 }}
    animate={{ opacity: 1, y: 0 }}
    exit={{ opacity: 0, y: -50 }}
    transition={{ duration: 0.5 }}
    className="bg-white p-6 rounded-lg shadow-lg max-w-2xl mx-auto text-center"
  >
    <img
      src={testimonial.image}
      alt={testimonial.name}
      className="w-24 h-24 rounded-full mx-auto mb-4"
    />
    <blockquote className="text-xl italic mb-4">
      "{testimonial.quote}"
    </blockquote>
    <p className="font-bold">{testimonial.name}</p>
    <p className="text-gray-600">{testimonial.role}</p>
  </motion.div>
);

export const DynamicTestimonials = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % testimonials.length);
    }, 10000);
    return () => clearInterval(timer);
  }, []);

  const nextTestimonial = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % testimonials.length);
  };

  const prevTestimonial = () => {
    setCurrentIndex(
      (prevIndex) => (prevIndex - 1 + testimonials.length) % testimonials.length
    );
  };

  return (
    <section className="bg-gray-100 py-16">
      <div className="container mx-auto px-4 relative">
        <h2 className="text-3xl font-bold text-center mb-12">
          Lo que dicen nuestros usuarios
        </h2>
        <AnimatePresence mode="wait">
          <Testimonial testimonial={testimonials[currentIndex]} />
        </AnimatePresence>
        <button
          onClick={prevTestimonial}
          className="absolute left-4 top-1/2 transform -translate-y-1/2 bg-white p-2 rounded-full shadow-md"
        >
          <ChevronLeft className="w-6 h-6" />
        </button>
        <button
          onClick={nextTestimonial}
          className="absolute right-4 top-1/2 transform -translate-y-1/2 bg-white p-2 rounded-full shadow-md"
        >
          <ChevronRight className="w-6 h-6" />
        </button>
      </div>
    </section>
  );
};
