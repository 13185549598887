import React from "react";

export const Select = ({ options, value, onChange, multiple, ...props }) => {
  return (
    <select
      value={value}
      onChange={(e) =>
        onChange(
          multiple
            ? Array.from(e.target.selectedOptions, (option) => option.value)
            : e.target.value
        )
      }
      multiple={multiple}
      className="form-select mt-1 block w-full"
      {...props}
    >
      {options.map((option) => (
        <option key={option} value={option}>
          {option}
        </option>
      ))}
    </select>
  );
};
