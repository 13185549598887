import React, { useState, useEffect } from "react";
import { ProjectCard } from "./ProjectCard";
import { useNavigate } from "react-router-dom";
import { AdvancedFilter } from "./AdvancedFilter";
import { ArrowLeft } from "lucide-react";

const projects = [
  {
    id: 1,
    title: "Festival de Cine Indie",
    category: "Cine",
    fundingProgress: 75,
    image: "/images/indie-film-festival.jpg",
    goal: 100000,
    backers: 230,
    description:
      "Un festival que celebra el cine independiente y experimental.",
    location: "Madrid",
    date: "15-17 Septiembre, 2024",
  },
  {
    id: 2,
    title: "Gira Nacional de Jazz",
    category: "Música",
    fundingProgress: 60,
    image: "/images/jazz-tour.jpg",
    goal: 80000,
    backers: 180,
    description:
      "Una gira que lleva el mejor jazz a todas las esquinas del país.",
    location: "Varias ciudades",
    date: "Octubre - Noviembre, 2024",
  },
  {
    id: 3,
    title: "Restauración de Teatro",
    category: "Teatro",
    fundingProgress: 90,
    image: "/images/theater-restoration.jpg",
    goal: 150000,
    backers: 315,
    description: "Proyecto para devolver el esplendor a un histórico teatro.",
    location: "Barcelona",
    date: "Enero - Junio, 2025",
  },
  {
    id: 4,
    title: "Exposición de Arte Digital",
    category: "Artes Visuales",
    fundingProgress: 40,
    image: "/images/digital-art-expo.jpg",
    goal: 50000,
    backers: 120,
    description: "Una exposición innovadora que fusiona arte y tecnología.",
    location: "Valencia",
    date: "Agosto, 2024",
  },
  {
    id: 5,
    title: "Festival de Literatura Fantástica",
    category: "Literatura",
    fundingProgress: 55,
    image: "/images/fantasy-lit-fest.jpg",
    goal: 70000,
    backers: 210,
    description:
      "Un evento que reúne a los mejores autores del género fantástico.",
    location: "Sevilla",
    date: "12-15 Noviembre, 2024",
  },
];

export const ProjectList = () => {
  const [filteredProjects, setFilteredProjects] = useState(projects);
  const [selectedCategory, setSelectedCategory] = useState("Todos");
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleFilterChange = (filters) => {
    let newFilteredProjects = projects;

    if (filters.category && filters.category !== "Todos") {
      newFilteredProjects = newFilteredProjects.filter(
        (project) => project.category === filters.category
      );
      setSelectedCategory(filters.category);
    } else {
      setSelectedCategory("Todos");
    }

    setFilteredProjects(newFilteredProjects);
  };

  const handleViewDetails = (projectId) => {
    navigate(`/project/${projectId}`, { state: { fromProjects: true } });
  };

  const handleResetFilters = () => {
    setFilteredProjects(projects);
    setSelectedCategory("Todos");
  };

  return (
    <div className="bg-white p-8 rounded-xl shadow-lg">
      <h1 className="text-3xl font-bold mb-8">Todos los Proyectos</h1>
      <AdvancedFilter onFilterChange={handleFilterChange} />

      {selectedCategory !== "Todos" && (
        <button
          onClick={handleResetFilters}
          className="mb-4 flex items-center text-indigo-600 hover:text-indigo-800"
        >
          <ArrowLeft size={20} className="mr-2" />
          Volver a todos los proyectos
        </button>
      )}

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 mt-8">
        {filteredProjects.map((project) => (
          <ProjectCard
            key={project.id}
            project={project}
            onViewDetails={handleViewDetails}
          />
        ))}
      </div>
    </div>
  );
};
