import React, { useState } from "react";
import { Input } from "./ui/input";
import { Checkbox } from "./ui/checkbox";
import { Select } from "./ui/select";
import { Button } from "./ui/button";
import { SlidersHorizontal, X } from "lucide-react";
import { motion, AnimatePresence } from "framer-motion";

export const AdvancedFilter = ({ onFilterChange }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [filters, setFilters] = useState({
    keyword: "",
    categories: [],
    comunidadAutonoma: "Todas",
    taxBenefits: false,
  });

  const handleChange = (key, value) => {
    setFilters((prev) => ({ ...prev, [key]: value }));
  };

  const handleCategoryToggle = (category) => {
    setFilters((prev) => ({
      ...prev,
      categories: prev.categories.includes(category)
        ? prev.categories.filter((c) => c !== category)
        : [...prev.categories, category],
    }));
  };

  const handleApplyFilters = () => {
    onFilterChange(filters);
    setIsOpen(false);
  };

  const categories = [
    "Artes Escénicas",
    "Música",
    "Cine",
    "Literatura",
    "Artes Visuales",
    "Patrimonio",
    "Nuevos Medios",
  ];

  const comunidadesAutonomas = [
    "Todas",
    "Andalucía",
    "Aragón",
    "Asturias",
    "Baleares",
    "Canarias",
    "Cantabria",
    "Castilla y León",
    "Castilla-La Mancha",
    "Cataluña",
    "Comunidad Valenciana",
    "Extremadura",
    "Galicia",
    "La Rioja",
    "Madrid",
    "Murcia",
    "Navarra",
    "País Vasco",
    "Ceuta",
    "Melilla",
  ];

  return (
    <div className="relative flex justify-end">
      <Button
        onClick={() => setIsOpen(!isOpen)}
        className="flex items-center space-x-2 bg-white text-gray-700 border border-gray-300 hover:bg-gray-100 transition-colors duration-200"
      >
        <SlidersHorizontal size={20} />
        <span>Filtros</span>
      </Button>

      <AnimatePresence>
        {isOpen && (
          <motion.div
            initial={{ opacity: 0, y: -10 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -10 }}
            transition={{ duration: 0.2 }}
            className="absolute top-full right-0 mt-2 w-80 bg-white rounded-lg shadow-lg z-10"
          >
            <div className="p-4 space-y-4">
              <div className="flex justify-between items-center">
                <h3 className="text-lg font-semibold">Filtros Avanzados</h3>
                <Button
                  onClick={() => setIsOpen(false)}
                  variant="ghost"
                  size="sm"
                >
                  <X size={20} />
                </Button>
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Palabra clave
                </label>
                <Input
                  type="text"
                  value={filters.keyword}
                  onChange={(e) => handleChange("keyword", e.target.value)}
                  placeholder="Buscar proyectos..."
                  className="w-full"
                />
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Categorías
                </label>
                <div className="flex flex-wrap gap-2">
                  {categories.map((category) => (
                    <Button
                      key={category}
                      onClick={() => handleCategoryToggle(category)}
                      size="sm"
                      variant={
                        filters.categories.includes(category)
                          ? "default"
                          : "outline"
                      }
                      className={
                        filters.categories.includes(category)
                          ? "bg-indigo-600 text-white"
                          : ""
                      }
                    >
                      {category}
                    </Button>
                  ))}
                </div>
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Comunidad Autónoma
                </label>
                <Select
                  options={comunidadesAutonomas}
                  value={filters.comunidadAutonoma}
                  onChange={(value) => handleChange("comunidadAutonoma", value)}
                  className="w-full"
                />
              </div>

              <div className="flex items-center">
                <Checkbox
                  checked={filters.taxBenefits}
                  onChange={(e) =>
                    handleChange("taxBenefits", e.target.checked)
                  }
                  id="taxBenefits"
                />
                <label
                  htmlFor="taxBenefits"
                  className="ml-2 text-sm text-gray-700"
                >
                  Solo proyectos con beneficios fiscales
                </label>
              </div>

              <Button
                onClick={handleApplyFilters}
                className="w-full bg-indigo-600 hover:bg-indigo-700 text-white font-bold py-2 px-4 rounded"
              >
                Aplicar Filtros
              </Button>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};
