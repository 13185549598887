import React, { useState } from "react";

const AccordionItem = ({ question, answer }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="border-b border-gray-200">
      <button
        className="flex justify-between w-full py-4 px-2 text-left font-semibold text-gray-800 hover:bg-gray-50 focus:outline-none focus:bg-gray-50 transition duration-150 ease-in-out"
        onClick={() => setIsOpen(!isOpen)}
      >
        <span>{question}</span>
        <span className="text-indigo-600">{isOpen ? "−" : "+"}</span>
      </button>
      {isOpen && <div className="p-4 bg-gray-50 text-gray-700">{answer}</div>}
    </div>
  );
};

export const FAQ = () => {
  const faqItems = [
    {
      question: "¿Cuál es el mecanismo de inversión en PRO_MECENAS?",
      answer: (
        <>
          <p>
            PRO_MECENAS opera mediante un sistema de inversión directa en
            proyectos culturales. El proceso se estructura de la siguiente
            manera:
          </p>
          <ol className="list-decimal list-inside mt-2 space-y-1">
            <li>Selección del proyecto cultural de interés.</li>
            <li>Determinación del monto de inversión (mínimo 1.0000€).</li>
            <li>Transferencia del capital al fondo escrow de PRO_MECENAS.</li>
            <li>Emisión de un certificado digital de inversión.</li>
            <li>
              Liberación de fondos al proyecto una vez alcanzado el objetivo de
              financiación.
            </li>
          </ol>
          <p className="mt-2">
            Este mecanismo garantiza la seguridad y transparencia de cada
            transacción.
          </p>
        </>
      ),
    },
    {
      question: "¿Cómo se aplica la deducción fiscal del 120%?",
      answer: (
        <>
          <p>
            La deducción fiscal del 120% se implementa de acuerdo con la
            legislación vigente sobre mecenazgo cultural. El proceso es el
            siguiente:
          </p>
          <ol className="list-decimal list-inside mt-2 space-y-1">
            <li>
              La inversión realizada se considera en su totalidad como base de
              deducción.
            </li>
            <li>Se aplica un factor multiplicador del 120% sobre esta base.</li>
            <li>
              El resultado se deduce directamente de la cuota íntegra del IRPF o
              Impuesto de Sociedades.
            </li>
          </ol>
          <p className="mt-2">
            Ejemplo: Una inversión de 10.000€ genera una deducción fiscal de
            12.000€, resultando en un ahorro fiscal neto de 2.000€.
          </p>
          <p className="mt-2">
            Es importante consultar con un asesor fiscal para optimizar la
            aplicación de esta deducción en su declaración de impuestos.
          </p>
        </>
      ),
    },
    {
      question:
        "¿Cuáles son los requisitos de elegibilidad para los proyectos culturales?",
      answer: (
        <>
          <p>
            Los proyectos culturales en PRO_MECENAS deben cumplir con criterios
            específicos para ser elegibles:
          </p>
          <ul className="list-disc list-inside mt-2 space-y-1">
            <li>
              Pertenecer a sectores culturales reconocidos: artes escénicas,
              música, cine, literatura, artes visuales, patrimonio cultural.
            </li>
            <li>Presentar un plan de negocio viable y sostenible.</li>
            <li>Demostrar impacto cultural y/o social significativo.</li>
            <li>
              Cumplir con los requisitos legales y fiscales para beneficiarse de
              las deducciones por mecenazgo.
            </li>
            <li>Superar el proceso de due diligence de PRO_MECENAS.</li>
          </ul>
          <p className="mt-2">
            Cada proyecto es evaluado por un comité de expertos para garantizar
            su calidad y viabilidad.
          </p>
        </>
      ),
    },
    {
      question: "¿Cómo se gestionan los riesgos de inversión en PRO_MECENAS?",
      answer: (
        <>
          <p>
            PRO_MECENAS implementa varias estrategias para mitigar los riesgos
            de inversión:
          </p>
          <ol className="list-decimal list-inside mt-2 space-y-1">
            <li>Due Diligence exhaustivo de cada proyecto.</li>
            <li>Diversificación de la cartera de proyectos.</li>
            <li>Monitoreo continuo del progreso de los proyectos.</li>
            <li>Establecimiento de hitos de financiación.</li>
            <li>Reserva de contingencia para cada proyecto.</li>
          </ol>
          <p className="mt-2">
            No obstante, es crucial entender que toda inversión conlleva
            riesgos. Se recomienda a los inversores diversificar sus inversiones
            y no comprometer más capital del que pueden permitirse perder.
          </p>
        </>
      ),
    },
    {
      question: "¿Cuál es el proceso de salida para los inversores?",
      answer: (
        <>
          <p>
            El proceso de salida para los inversores en PRO_MECENAS varía según
            la naturaleza del proyecto:
          </p>
          <ul className="list-disc list-inside mt-2 space-y-1">
            <li>
              Proyectos con retorno financiero: Se establecen plazos de reparto
              de beneficios según el rendimiento del proyecto.
            </li>
            <li>
              Proyectos sin ánimo de lucro: La salida se limita a la deducción
              fiscal y los beneficios intangibles del apoyo cultural.
            </li>
          </ul>
          <p className="mt-2">
            En algunos casos, PRO_MECENAS puede facilitar un mercado secundario
            para la transferencia de participaciones, sujeto a las regulaciones
            aplicables.
          </p>
          <p className="mt-2">
            Es importante notar que la liquidez de estas inversiones puede ser
            limitada, y los plazos de retorno pueden extenderse varios años,
            dependiendo de la naturaleza del proyecto cultural.
          </p>
        </>
      ),
    },
  ];

  return (
    <div className="bg-white p-6 rounded-lg shadow-md">
      <h2 className="text-3xl font-bold mb-6 text-gray-800">
        Preguntas Frecuentes
      </h2>
      <div className="space-y-2">
        {faqItems.map((item, index) => (
          <AccordionItem
            key={index}
            question={item.question}
            answer={item.answer}
          />
        ))}
      </div>
    </div>
  );
};
