import React, { useState, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import ContactModal from "./ContactModal";

const Header = () => {
  const [isScrolled, setIsScrolled] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 10);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const isHomePage = location.pathname === "/";

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  const handleLogoClick = (e) => {
    e.preventDefault();
    if (location.pathname.startsWith("/project/")) {
      navigate("/", { state: { scrollToTrending: true } });
    } else {
      navigate("/");
    }
  };

  return (
    <>
      <header
        className={`fixed w-full z-50 transition-all duration-300 ${
          isScrolled || !isHomePage ? "bg-white shadow" : "bg-transparent"
        }`}
      >
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-4 flex justify-between items-center">
          <a
            href="/"
            onClick={handleLogoClick}
            className={`text-2xl font-semibold ${
              isScrolled || !isHomePage ? "text-blue-600" : "text-white"
            }`}
          >
            PRO_MECENAS
          </a>
          <nav className="space-x-6">
            <Link
              to="/projects"
              className={`transition-colors ${
                isScrolled || !isHomePage
                  ? "text-gray-600 hover:text-blue-600"
                  : "text-white hover:text-blue-200"
              }`}
            >
              Proyectos
            </Link>
            <Link
              to="/investors"
              className={`transition-colors ${
                isScrolled || !isHomePage
                  ? "text-gray-600 hover:text-blue-600"
                  : "text-white hover:text-blue-200"
              }`}
            >
              Inversores
            </Link>
            <Link
              to="/about"
              className={`transition-colors ${
                isScrolled || !isHomePage
                  ? "text-gray-600 hover:text-blue-600"
                  : "text-white hover:text-blue-200"
              }`}
            >
              Acerca de
            </Link>
            <button
              onClick={openModal}
              className={`transition-colors ${
                isScrolled || !isHomePage
                  ? "text-gray-600 hover:text-blue-600"
                  : "text-white hover:text-blue-200"
              }`}
            >
              Contacto
            </button>
          </nav>
        </div>
      </header>
      <ContactModal isOpen={isModalOpen} onClose={closeModal} />
    </>
  );
};

export default Header;
