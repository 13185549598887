import React, { useEffect, useState } from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { motion } from "framer-motion";
import {
  Users,
  MapPin,
  Calendar,
  Clock,
  Target,
  ArrowLeft,
} from "lucide-react";
import { ContactModal } from "./ContactModal";

const projects = [
  {
    id: 1,
    title: "Festival de Cine Indie",
    category: "Cine",
    fundingProgress: 75,
    image: "/images/indie-film-festival.jpg",
    goal: 100000,
    backers: 230,
    description:
      "Un festival que celebra el cine independiente y experimental.",
    longDescription:
      "Este festival es una plataforma única para cineastas independientes de toda España. Durante tres días, Madrid se convertirá en el epicentro del cine alternativo, ofreciendo proyecciones, talleres y charlas con directores reconocidos. El festival busca promover nuevas voces en el cine y proporcionar un espacio para la experimentación y la innovación cinematográfica.",
    location: "Madrid",
    date: "15-17 Septiembre, 2024",
    duration: "3 días",
    rewards: [
      {
        amount: 10000,
        description:
          "Patrocinio principal del festival + Logotipo destacado en toda la publicidad",
      },
      {
        amount: 5000,
        description:
          "Patrocinio de una sección del festival + Presencia en materiales promocionales",
      },
      {
        amount: 2000,
        description:
          "Patrocinio de una proyección + Mención especial en la presentación",
      },
    ],
  },
  {
    id: 2,
    title: "Gira Nacional de Jazz",
    category: "Música",
    fundingProgress: 60,
    image: "/images/jazz-tour.jpg",
    goal: 80000,
    backers: 180,
    description:
      "Una gira que lleva el mejor jazz a todas las esquinas del país.",
    longDescription:
      "Esta gira nacional de jazz tiene como objetivo llevar la música jazz de alta calidad a ciudades y pueblos de toda España. Con actuaciones de artistas nacionales e internacionales, la gira no solo ofrecerá conciertos, sino también talleres y jam sessions para músicos locales. El proyecto busca fomentar la apreciación del jazz y crear conexiones entre músicos de diferentes regiones.",
    location: "Varias ciudades",
    date: "Octubre - Noviembre, 2024",
    duration: "2 meses",
    rewards: [
      {
        amount: 15000,
        description:
          "Patrocinador principal de la gira + Naming rights para el escenario principal",
      },
      {
        amount: 8000,
        description:
          "Patrocinio de una ciudad de la gira + Evento VIP exclusivo con los artistas",
      },
      {
        amount: 3000,
        description:
          "Patrocinio de un taller + Reconocimiento en el programa de la gira",
      },
    ],
  },
  {
    id: 3,
    title: "Restauración de Teatro",
    category: "Teatro",
    fundingProgress: 90,
    image: "/images/theater-restoration.jpg",
    goal: 150000,
    backers: 315,
    description: "Proyecto para devolver el esplendor a un histórico teatro.",
    longDescription:
      "Este proyecto ambicioso tiene como objetivo restaurar uno de los teatros más emblemáticos de Barcelona a su antigua gloria. La restauración incluirá la renovación de la fachada, la modernización de los sistemas de iluminación y sonido, y la restauración de los frescos y obras de arte interiores. El teatro renovado no solo será un espacio para actuaciones, sino también un centro cultural que ofrecerá programas educativos y comunitarios.",
    location: "Barcelona",
    date: "Enero - Junio, 2025",
    duration: "6 meses",
    rewards: [
      {
        amount: 25000,
        description:
          "Patrocinador principal de la restauración + Naming rights para una sala del teatro",
      },
      {
        amount: 12000,
        description:
          "Patrocinio de la restauración de un área específica + Placa conmemorativa",
      },
      {
        amount: 5000,
        description:
          "Patrocinio de un elemento artístico + Invitación VIP a la gala de reapertura",
      },
    ],
  },
  {
    id: 4,
    title: "Exposición de Arte Digital",
    category: "Artes Visuales",
    fundingProgress: 40,
    image: "/images/digital-art-expo.jpg",
    goal: 50000,
    backers: 120,
    description: "Una exposición innovadora que fusiona arte y tecnología.",
    longDescription:
      "Esta exposición de arte digital presenta obras vanguardistas que exploran la intersección entre el arte y la tecnología. Artistas de renombre internacional exhibirán instalaciones interactivas, realidad virtual, y proyecciones inmersivas. La exposición busca desafiar las nociones tradicionales del arte y explorar cómo la tecnología está transformando nuestra percepción y experiencia del mundo visual.",
    location: "Valencia",
    date: "Agosto, 2024",
    duration: "1 mes",
    rewards: [
      {
        amount: 8000,
        description:
          "Patrocinador principal de la exposición + Logotipo en todas las obras digitales",
      },
      {
        amount: 4000,
        description:
          "Patrocinio de una instalación interactiva + Mención en la guía de la exposición",
      },
      {
        amount: 1500,
        description:
          "Patrocinio de una charla de artista + Acceso VIP a la inauguración",
      },
    ],
  },
  {
    id: 5,
    title: "Festival de Literatura Fantástica",
    category: "Literatura",
    fundingProgress: 55,
    image: "/images/fantasy-lit-fest.jpg",
    goal: 70000,
    backers: 210,
    description:
      "Un evento que reúne a los mejores autores del género fantástico.",
    longDescription:
      "Este festival celebra lo mejor de la literatura fantástica, reuniendo a autores de renombre, editores y fans del género. El evento incluirá lecturas de autores, paneles de discusión, talleres de escritura creativa y una feria del libro especializada. Además, habrá concursos de cosplay y actividades interactivas basadas en mundos de fantasía populares.",
    location: "Sevilla",
    date: "12-15 Noviembre, 2024",
    duration: "4 días",
    rewards: [
      {
        amount: 12000,
        description:
          "Patrocinador principal del festival + Naming rights para el escenario principal",
      },
      {
        amount: 6000,
        description:
          "Patrocinio de un panel de autores + Espacio destacado en la feria del libro",
      },
      {
        amount: 2500,
        description:
          "Patrocinio de un taller de escritura + Menciones en redes sociales del evento",
      },
    ],
  },
];

export const ProjectDetail = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const [project, setProject] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    const foundProject = projects.find((p) => p.id === parseInt(id));
    setProject(foundProject);
    window.scrollTo(0, 0);
  }, [id]);

  const handleGoBack = () => {
    if (location.state && location.state.fromTrending) {
      navigate("/", { state: { scrollToTrending: true } });
    } else {
      navigate("/projects");
    }
  };

  if (!project) {
    return <div className="text-center py-20">Cargando proyecto...</div>;
  }

  return (
    <div className="bg-white p-8 rounded-xl shadow-lg max-w-4xl mx-auto my-12">
      <button
        onClick={handleGoBack}
        className="mb-4 flex items-center text-indigo-600 hover:text-indigo-800"
      >
        <ArrowLeft size={20} className="mr-2" />
        Volver a{" "}
        {location.state && location.state.fromTrending
          ? "trending"
          : "todos los proyectos"}
      </button>

      <motion.img
        src={project.image}
        alt={project.title}
        className="w-full h-64 object-cover rounded-xl mb-8"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.5 }}
      />
      <motion.h1
        className="text-4xl font-bold mb-4"
        initial={{ opacity: 0, y: -20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5, delay: 0.2 }}
      >
        {project.title}
      </motion.h1>
      <motion.div
        className="mb-8"
        initial={{ opacity: 0, y: -20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5, delay: 0.3 }}
      >
        <span className="text-sm font-semibold text-indigo-600 uppercase tracking-wide">
          {project.category}
        </span>
        <div className="flex items-center mt-2 text-gray-600">
          <MapPin size={16} className="mr-2" />
          <span>{project.location}</span>
          <Calendar size={16} className="ml-4 mr-2" />
          <span>{project.date}</span>
          <Clock size={16} className="ml-4 mr-2" />
          <span>{project.duration}</span>
        </div>
      </motion.div>
      <motion.p
        className="text-gray-700 mb-8"
        initial={{ opacity: 0, y: -20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5, delay: 0.4 }}
      >
        {project.longDescription}
      </motion.p>
      <motion.div
        className="mb-8"
        initial={{ opacity: 0, y: -20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5, delay: 0.5 }}
      >
        <h2 className="text-2xl font-semibold mb-4">
          Progreso de financiación
        </h2>
        <div className="relative pt-1">
          <div className="overflow-hidden h-2 mb-4 text-xs flex rounded bg-gray-200">
            <motion.div
              style={{ width: `${project.fundingProgress}%` }}
              className="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-green-500"
              initial={{ width: 0 }}
              animate={{ width: `${project.fundingProgress}%` }}
              transition={{ duration: 1, ease: "easeOut" }}
            />
          </div>
        </div>
        <div className="flex justify-between text-sm text-gray-600">
          <span className="font-semibold">
            {project.fundingProgress}% financiado
          </span>
          <span className="flex items-center">
            <Users size={16} className="mr-1" />
            {project.backers} inversores
          </span>
          <span className="flex items-center">
            <Target size={16} className="mr-1" />
            {project.goal.toLocaleString()}€ objetivo
          </span>
        </div>
      </motion.div>
      <motion.div
        initial={{ opacity: 0, y: -20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5, delay: 0.6 }}
      >
        <h2 className="text-2xl font-semibold mb-4">
          Oportunidades de Patrocinio
        </h2>
        {project.rewards.map((reward, index) => (
          <div key={index} className="bg-gray-100 p-4 rounded-lg mb-4">
            <h3 className="font-semibold">{reward.amount.toLocaleString()}€</h3>
            <p>{reward.description}</p>
          </div>
        ))}
      </motion.div>
      <motion.button
        className="mt-8 w-full bg-indigo-600 text-white text-lg font-bold py-4 px-6 rounded-xl hover:bg-indigo-700 transition-colors"
        whileHover={{ scale: 1.02 }}
        whileTap={{ scale: 0.98 }}
        initial={{ opacity: 0, y: -20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5, delay: 0.7 }}
        onClick={() => setIsModalOpen(true)}
      >
        Contactar para invertir
      </motion.button>

      <ContactModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
      />
    </div>
  );
};
