import React from "react";
import { Users, MapPin, Calendar } from "lucide-react";

export const ProjectCard = ({ project, onViewDetails }) => {
  return (
    <div className="bg-white rounded-lg overflow-hidden shadow-md hover:shadow-xl transition-all duration-300">
      <img
        src={project.image}
        alt={project.title}
        className="w-full h-48 object-cover"
      />
      <div className="p-4">
        <div className="text-xs font-semibold text-indigo-600 uppercase tracking-wide mb-1">
          {project.category}
        </div>
        <h3 className="text-lg font-semibold text-gray-900 mb-2">
          {project.title}
        </h3>
        <p className="text-gray-600 text-sm mb-4">{project.description}</p>
        <div className="mb-4">
          <div className="relative pt-1">
            <div className="overflow-hidden h-2 mb-4 text-xs flex rounded bg-gray-200">
              <div
                style={{ width: `${project.fundingProgress}%` }}
                className="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-green-500"
              ></div>
            </div>
          </div>
          <div className="flex justify-between text-sm text-gray-600">
            <span>{project.fundingProgress}% financiado</span>
            <span className="flex items-center">
              <Users size={16} className="mr-1" />
              {project.backers} inversores
            </span>
          </div>
        </div>
        <div className="text-sm text-gray-600 space-y-1 mb-4">
          <p className="flex items-center">
            <MapPin size={16} className="mr-2 text-gray-400" />
            {project.location}
          </p>
          <p className="flex items-center">
            <Calendar size={16} className="mr-2 text-gray-400" />
            {project.date}
          </p>
        </div>
        <button
          onClick={() => onViewDetails(project.id)}
          className="w-full bg-indigo-600 text-white py-2 px-4 rounded-lg hover:bg-indigo-700 transition-colors"
        >
          Ver detalles
        </button>
      </div>
    </div>
  );
};
