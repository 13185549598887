import React from "react";
import { motion, AnimatePresence } from "framer-motion";
import { X, Copy } from "lucide-react";

export const ContactModal = ({ isOpen, onClose }) => {
  const copyToClipboard = () => {
    navigator.clipboard.writeText("contacto@promecenas.com");
    // Aquí podrías añadir una notificación de que se ha copiado al portapapeles
  };

  return (
    <AnimatePresence>
      {isOpen && (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50"
          onClick={onClose}
        >
          <motion.div
            initial={{ scale: 0.9, opacity: 0 }}
            animate={{ scale: 1, opacity: 1 }}
            exit={{ scale: 0.9, opacity: 0 }}
            className="bg-white rounded-lg p-6 max-w-sm w-full m-4"
            onClick={(e) => e.stopPropagation()}
          >
            <div className="flex justify-between items-center mb-4">
              <h2 className="text-2xl font-bold">Contacto</h2>
              <button
                onClick={onClose}
                className="text-gray-500 hover:text-gray-700"
              >
                <X size={24} />
              </button>
            </div>
            <p className="mb-4">
              Para más información o para invertir, contáctanos en:
            </p>
            <div className="flex items-center justify-between bg-gray-100 p-2 rounded">
              <span className="font-medium">contacto@promecenas.com</span>
              <button
                onClick={copyToClipboard}
                className="text-indigo-600 hover:text-indigo-800"
                title="Copiar al portapapeles"
              >
                <Copy size={20} />
              </button>
            </div>
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};
export default ContactModal;
