import React, { useEffect, useRef } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useLocation,
} from "react-router-dom";
import Header from "./components/Header";
import HeroSection from "./components/HeroSection";
import { TrendingProjects } from "./components/TrendingProjects";
import { ProjectList } from "./components/ProjectList";
import { ProjectDetail } from "./components/ProjectDetail";
import { InvestmentSimulator } from "./components/InvestmentSimulator";
import { DynamicTestimonials } from "./components/DynamicTestimonials";
import { CulturalImpact } from "./components/CulturalImpact";
import { SuccessGallery } from "./components/SuccessGallery";
import { FAQ } from "./components/FAQ";
import { LiveChat } from "./components/LiveChat";

const ScrollToTrending = () => {
  const location = useLocation();
  const trendingRef = useRef(null);

  useEffect(() => {
    if (
      location.state &&
      location.state.scrollToTrending &&
      trendingRef.current
    ) {
      trendingRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [location]);

  return (
    <div ref={trendingRef}>
      <TrendingProjects />
    </div>
  );
};

function App() {
  return (
    <Router>
      <div className="flex flex-col min-h-screen">
        <Header />
        <main className="flex-grow">
          <Routes>
            <Route
              path="/"
              element={
                <>
                  <HeroSection />
                  <div className="content-wrapper">
                    <ScrollToTrending />
                    <InvestmentSimulator />
                    <DynamicTestimonials />
                    <CulturalImpact />
                    <SuccessGallery />
                    <FAQ />
                  </div>
                </>
              }
            />
            <Route
              path="/projects"
              element={
                <div className="content-wrapper">
                  <ProjectList />
                </div>
              }
            />
            <Route
              path="/project/:id"
              element={
                <div className="content-wrapper">
                  <ProjectDetail />
                </div>
              }
            />
          </Routes>
        </main>
        <footer className="bg-gray-800 text-white py-8">
          {/* Footer content */}
        </footer>
        <LiveChat />
      </div>
    </Router>
  );
}

export default App;
