import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";
import { Euro, PiggyBank, Calculator, TrendingUp } from "lucide-react";

export const InvestmentSimulator = () => {
  const [investment, setInvestment] = useState(10000);
  const [projectParticipation, setProjectParticipation] = useState(0);
  const [taxDeduction, setTaxDeduction] = useState(0);
  const [totalBenefit, setTotalBenefit] = useState(0);

  useEffect(() => {
    setProjectParticipation(investment);
    const calculatedDeduction = investment * 1.2;
    setTaxDeduction(calculatedDeduction);
    setTotalBenefit(investment + calculatedDeduction);
  }, [investment]);

  const predefinedAmounts = [10000, 25000, 50000, 100000, 250000];

  const formatNumber = (num) => new Intl.NumberFormat("es-ES").format(num);

  const BenefitCard = ({ icon, title, value, description }) => (
    <motion.div
      className="bg-white p-6 rounded-xl shadow-md flex flex-col h-full"
      whileHover={{ y: -5 }}
    >
      <div className="flex items-center mb-2">
        {icon}
        <h3 className="text-lg font-semibold ml-2">{title}</h3>
      </div>
      <p className="text-3xl font-bold text-indigo-600 mb-2 break-all">
        {value}
      </p>
      <p className="text-sm text-gray-600 mt-auto">{description}</p>
    </motion.div>
  );

  return (
    <div className="bg-gradient-to-br from-indigo-50 to-blue-100 rounded-xl shadow-lg p-8 max-w-4xl mx-auto">
      <h2 className="text-3xl font-bold mb-8 text-center text-indigo-800">
        Simulador de Inversión Cultural
      </h2>

      <div className="mb-8">
        <label className="block text-lg font-medium text-indigo-700 mb-4">
          Seleccione o introduzca la cantidad a invertir (€)
        </label>
        <div className="flex flex-wrap gap-3 mb-6">
          {predefinedAmounts.map((amount) => (
            <motion.button
              key={amount}
              onClick={() => setInvestment(amount)}
              className={`px-6 py-3 rounded-full text-sm font-semibold transition-colors ${
                investment === amount
                  ? "bg-indigo-600 text-white"
                  : "bg-white text-indigo-600 hover:bg-indigo-100"
              }`}
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
            >
              {formatNumber(amount)}€
            </motion.button>
          ))}
        </div>
        <input
          type="number"
          value={investment}
          onChange={(e) => setInvestment(Number(e.target.value))}
          min="10000"
          step="1000"
          className="w-full px-4 py-3 text-lg border border-indigo-300 rounded-lg focus:ring-2 focus:ring-indigo-500 focus:border-transparent"
        />
      </div>

      <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mb-8">
        <BenefitCard
          icon={
            <PiggyBank className="text-indigo-600 flex-shrink-0" size={24} />
          }
          title="Participación en Proyecto"
          value={`${formatNumber(projectParticipation)}€`}
          description="Valor de tu participación en el proyecto cultural"
        />
        <BenefitCard
          icon={
            <Calculator className="text-green-600 flex-shrink-0" size={24} />
          }
          title="Deducción Fiscal (120%)"
          value={`${formatNumber(taxDeduction)}€`}
          description="Cantidad deducible en tu declaración de impuestos"
        />
        <BenefitCard
          icon={
            <TrendingUp className="text-blue-600 flex-shrink-0" size={24} />
          }
          title="Beneficio Total Obtenido"
          value={`${formatNumber(totalBenefit)}€`}
          description="Suma de tu participación y la deducción fiscal"
        />
      </div>

      <div className="bg-white p-6 rounded-xl shadow-inner mb-8">
        <h3 className="text-2xl font-semibold mb-4 text-indigo-800">
          Resumen de la Inversión
        </h3>
        <div className="flex justify-between items-center text-lg">
          <span className="font-medium text-gray-700">Inversión Inicial:</span>
          <span className="font-bold text-indigo-600">
            {formatNumber(investment)}€
          </span>
        </div>
        <div className="flex justify-between items-center text-lg mt-2">
          <span className="font-medium text-gray-700">Beneficio Fiscal:</span>
          <span className="font-bold text-green-600">
            {formatNumber(taxDeduction - investment)}€
          </span>
        </div>
        <div className="flex justify-between items-center text-lg mt-2">
          <span className="font-medium text-gray-700">
            Valor Total Generado:
          </span>
          <span className="font-bold text-blue-600">
            {formatNumber(totalBenefit)}€
          </span>
        </div>
      </div>

      <motion.div
        className="bg-indigo-100 p-6 rounded-xl border border-indigo-200 mb-8"
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
      >
        <h4 className="text-xl font-semibold mb-3 text-indigo-800">
          <Euro className="inline-block mr-2" size={24} />
          Maximiza tu Impacto Cultural y Fiscal
        </h4>
        <p className="text-indigo-700 text-lg">
          Al invertir {formatNumber(investment)}€ en proyectos culturales a
          través de PRO_MECENAS, no solo obtienes una participación valorada en{" "}
          {formatNumber(projectParticipation)}€, sino que también generas una
          deducción fiscal de {formatNumber(taxDeduction)}€. Tu inversión
          cultural crea un valor total de {formatNumber(totalBenefit)}€,
          impulsando el arte y la cultura mientras optimizas tus beneficios
          fiscales.
        </p>
      </motion.div>

      <motion.button
        className="w-full bg-indigo-600 text-white text-lg font-bold py-4 px-6 rounded-xl hover:bg-indigo-700 transition-colors"
        whileHover={{ scale: 1.02 }}
        whileTap={{ scale: 0.98 }}
      >
        Invertir Ahora
      </motion.button>
    </div>
  );
};
